define("@ember-decorators/argument/-debug/errors", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var MutabilityError = exports.MutabilityError = function (_Error) {
    _inherits(MutabilityError, _Error);

    function MutabilityError() {
      _classCallCheck(this, MutabilityError);

      return _possibleConstructorReturn(this, (MutabilityError.__proto__ || Object.getPrototypeOf(MutabilityError)).apply(this, arguments));
    }

    return MutabilityError;
  }(Error);

  var RequiredFieldError = exports.RequiredFieldError = function (_Error2) {
    _inherits(RequiredFieldError, _Error2);

    function RequiredFieldError() {
      _classCallCheck(this, RequiredFieldError);

      return _possibleConstructorReturn(this, (RequiredFieldError.__proto__ || Object.getPrototypeOf(RequiredFieldError)).apply(this, arguments));
    }

    return RequiredFieldError;
  }(Error);

  var TypeError = exports.TypeError = function (_Error3) {
    _inherits(TypeError, _Error3);

    function TypeError() {
      _classCallCheck(this, TypeError);

      return _possibleConstructorReturn(this, (TypeError.__proto__ || Object.getPrototypeOf(TypeError)).apply(this, arguments));
    }

    return TypeError;
  }(Error);
});
define('@ember-decorators/argument/-debug/utils/validation-decorator', ['exports', '@ember-decorators/argument/-debug/utils/validations-for', '@ember-decorators/argument/-debug/errors'], function (exports, _validationsFor, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = validationDecorator;


  function makeComputed(desc) {
    if (true) {
      return Ember.computed(desc);
    } else {
      var get = desc.get,
          set = desc.set;


      return Ember.computed(function (key, value) {
        if (arguments.length > 1) {
          return set.call(this, key, value);
        }

        return get.call(this);
      });
    }
  }

  function runValidators(validators, constructor, key, value) {
    validators.forEach(function (validator) {
      if (validator(value) === false) {
        throw new _errors.TypeError(constructor + '#' + key + ' expected value of type ' + validator + ', but received: ' + value);
      }
    });
  }

  Ember.Object.reopenClass({
    create: function create() {
      var instance = this._super.apply(this, arguments);

      var constructor = this;
      var prototype = Object.getPrototypeOf(instance);
      var validations = (0, _validationsFor.getValidationsFor)(prototype);

      if (!validations) {
        return instance;
      }

      var _loop = function _loop(key) {
        var _validations$key = validations[key],
            isImmutable = _validations$key.isImmutable,
            isRequired = _validations$key.isRequired,
            typeValidators = _validations$key.typeValidators,
            typeRequired = _validations$key.typeRequired;


        if (isRequired && !instance.hasOwnProperty(key)) {
          throw new _errors.RequiredFieldError(constructor + ' requires a \'' + key + '\' argument to be passed in when using the component');
        }

        var value = Ember.get(instance, key);

        if (typeValidators.length > 0) {
          runValidators(typeValidators, constructor, key, value);
        } else if (typeRequired) {
          throw new _errors.TypeError(constructor + '#' + key + ' requires a type, add one using the @type decorator');
        }

        if (isImmutable) {
          instance[key] = makeComputed({
            get: function get() {
              return value;
            },
            set: function set(key, value) {
              throw new _errors.MutabilityError('Attempted to set ' + constructor + '#' + key + ' to the value ' + value + ' but the field is immutable');
            }
          });
        } else if (typeValidators.length) {
          var cachedValue = value;

          instance[key] = makeComputed({
            get: function get() {
              return cachedValue;
            },
            set: function set(key, value) {
              runValidators(typeValidators, constructor, key, value);

              return cachedValue = value;
            }
          });
        }
      };

      for (var key in validations) {
        _loop(key);
      }

      return instance;
    }
  });

  function validationDecorator(fn) {
    return function (target, key, desc, options) {
      var validations = (0, _validationsFor.getValidationsForKey)(target, key);

      // always ensure the property is writeable, doesn't make sense otherwise (babel bug?)
      desc.writable = true;
      desc.configurable = true;

      fn(target, key, desc, options, validations);

      if (desc.initializer === null) desc.initializer = undefined;
    };
  }
});
define('vertical-collection/-private', ['exports', 'ember-raf-scheduler'], function (exports, emberRafScheduler) { 'use strict';

var guidFor = Ember.guidFor;


function identity(item) {
  var key = void 0;
  var type = typeof item;

  if (type === 'string' || type === 'number') {
    key = item;
  } else {
    key = guidFor(item);
  }

  return key;
}

var get = Ember.get;


function keyForItem(item, keyPath, index) {
  var key = void 0;

  (true && !(typeof keyPath === 'string') && Ember.assert('keyPath must be a string, received: ' + keyPath, typeof keyPath === 'string'));


  switch (keyPath) {
    case '@index':
      (true && !(typeof index === 'number') && Ember.assert('A numerical index must be supplied for keyForItem when keyPath is @index, received: ' + index, typeof index === 'number'));

      key = index;
      break;
    case '@identity':
      key = identity(item);
      break;
    default:
      key = get(item, keyPath);
  }

  if (typeof key === 'number') {
    key = String(key);
  }

  return key;
}

var VENDOR_MATCH_FNS = ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'];
var ELEMENT_MATCH_FN = void 0;

function setElementMatchFn(el) {
  VENDOR_MATCH_FNS.forEach(function (fn) {
    if (ELEMENT_MATCH_FN === undefined && typeof el[fn] === 'function') {
      ELEMENT_MATCH_FN = fn;
    }
  });
}

function closest(el, selector) {
  if (ELEMENT_MATCH_FN === undefined) {
    setElementMatchFn(el);
  }
  while (el) {
    // TODO add explicit test
    if (el[ELEMENT_MATCH_FN](selector)) {
      return el;
    }
    el = el.parentElement;
  }

  return null;
}

var _createClass$2 = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var set$1 = Ember.set;


var VC_IDENTITY = 0;

var VirtualComponent = function () {
  function VirtualComponent() {
    var content = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var index = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

    this.id = VC_IDENTITY++;

    this.content = content;
    this.index = index;

    this.upperBound = document.createTextNode('');
    this.lowerBound = document.createTextNode('');
    this.element = null;

    this.rendered = false;

    // In older versions of Ember/IE, binding anything on an object in the template
    // adds observers which creates __ember_meta__
    this.__ember_meta__ = null; // eslint-disable-line camelcase

    {
      Object.preventExtensions(this);
    }
  }

  VirtualComponent.prototype.getBoundingClientRect = function getBoundingClientRect() {
    var upperBound = this.upperBound,
        lowerBound = this.lowerBound;


    var top = Infinity;
    var bottom = -Infinity;

    while (upperBound !== lowerBound) {
      upperBound = upperBound.nextSibling;

      if (upperBound instanceof Element) {
        top = Math.min(top, upperBound.getBoundingClientRect().top);
        bottom = Math.max(bottom, upperBound.getBoundingClientRect().bottom);
      }

      {
        if (upperBound instanceof Element) {
          continue;
        }

        var text = upperBound.textContent;

        (true && !(text === '' || text.match(/^\s+$/)) && Ember.assert('All content inside of vertical-collection must be wrapped in an element. Detected a text node with content: ' + text, text === '' || text.match(/^\s+$/)));
      }
    }

    (true && !(top !== Infinity && bottom !== -Infinity) && Ember.assert('Items in a vertical collection require atleast one element in them', top !== Infinity && bottom !== -Infinity));


    var height = bottom - top;

    return { top: top, bottom: bottom, height: height };
  };

  VirtualComponent.prototype.recycle = function recycle(newContent, newIndex) {
    (true && !(newContent) && Ember.assert('You cannot set an item\'s content to undefined', newContent));


    if (this.index !== newIndex) {
      set$1(this, 'index', newIndex);
    }

    if (this.content !== newContent) {
      set$1(this, 'content', newContent);
    }
  };

  VirtualComponent.prototype.destroy = function destroy() {
    set$1(this, 'element', null);
    set$1(this, 'upperBound', null);
    set$1(this, 'lowerBound', null);
    set$1(this, 'content', null);
    set$1(this, 'index', null);
  };

  _createClass$2(VirtualComponent, [{
    key: 'realUpperBound',
    get: function get() {
      return this.upperBound;
    }
  }, {
    key: 'realLowerBound',
    get: function get() {
      return this.lowerBound;
    }
  }]);

  return VirtualComponent;
}();

function insertRangeBefore(parent, element, firstNode, lastNode) {
  var nextNode = void 0;

  while (firstNode) {
    nextNode = firstNode.nextSibling;
    parent.insertBefore(firstNode, element);

    if (firstNode === lastNode) {
      break;
    }

    firstNode = nextNode;
  }
}

function objectAt(arr, index) {
  (true && !(Array.isArray(arr) || typeof arr.objectAt === 'function') && Ember.assert('arr must be an instance of a Javascript Array or implement `objectAt`', Array.isArray(arr) || typeof arr.objectAt === 'function'));


  return arr.objectAt ? arr.objectAt(index) : arr[index];
}

function roundTo(number) {
  var decimal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;

  var exp = Math.pow(10, decimal);
  return Math.round(number * exp) / exp;
}

var supportsPassive = false;

try {
  var opts = Object.defineProperty({}, 'passive', {
    get: function get() {
      supportsPassive = true;
    }
  });

  window.addEventListener('test', null, opts);
} catch (e) {
  // do nothing
}

var SUPPORTS_PASSIVE = supportsPassive;

var run = Ember.run;

var DEFAULT_ARRAY_SIZE = 10;
var UNDEFINED_VALUE = Object.create(null);

var ScrollHandler = function () {
  function ScrollHandler() {
    this.elements = new Array(DEFAULT_ARRAY_SIZE);
    this.maxLength = DEFAULT_ARRAY_SIZE;
    this.length = 0;
    this.handlers = new Array(DEFAULT_ARRAY_SIZE);
    this.isPolling = false;
    this.isUsingPassive = SUPPORTS_PASSIVE;
  }

  ScrollHandler.prototype.addScrollHandler = function addScrollHandler(element, handler) {
    var index = this.elements.indexOf(element);
    var handlers = void 0,
        cache = void 0;

    if (index === -1) {
      index = this.length++;

      if (index === this.maxLength) {
        this.maxLength *= 2;
        this.elements.length = this.maxLength;
        this.handlers.length = this.maxLength;
      }

      handlers = [handler];

      this.elements[index] = element;
      cache = this.handlers[index] = {
        top: element.scrollTop,
        left: element.scrollLeft,
        handlers: handlers
      };
      // TODO add explicit test
      if (SUPPORTS_PASSIVE) {
        cache.passiveHandler = function () {
          ScrollHandler.triggerElementHandlers(element, cache);
        };
      } else {
        cache.passiveHandler = UNDEFINED_VALUE;
      }
    } else {
      cache = this.handlers[index];
      handlers = cache.handlers;
      handlers.push(handler);
    }

    // TODO add explicit test
    if (this.isUsingPassive && handlers.length === 1) {
      element.addEventListener('scroll', cache.passiveHandler, { capture: true, passive: true });

      // TODO add explicit test
    } else if (!this.isPolling) {
      this.poll();
    }
  };

  ScrollHandler.prototype.removeScrollHandler = function removeScrollHandler(element, handler) {
    var index = this.elements.indexOf(element);
    var elementCache = this.handlers[index];
    // TODO add explicit test
    if (elementCache && elementCache.handlers) {
      var _index = elementCache.handlers.indexOf(handler);

      if (_index === -1) {
        throw new Error('Attempted to remove an unknown handler');
      }

      elementCache.handlers.splice(_index, 1);

      // cleanup element entirely if needed
      // TODO add explicit test
      if (!elementCache.handlers.length) {
        _index = this.elements.indexOf(element);
        this.handlers.splice(_index, 1);
        this.elements.splice(_index, 1);

        this.length--;
        this.maxLength--;

        if (this.length === 0) {
          this.isPolling = false;
        }

        // TODO add explicit test
        if (this.isUsingPassive) {
          element.removeEventListener('scroll', elementCache.passiveHandler, { capture: true, passive: true });
        }
      }
    } else {
      throw new Error('Attempted to remove a handler from an unknown element or an element with no handlers');
    }
  };

  ScrollHandler.triggerElementHandlers = function triggerElementHandlers(element, meta) {
    var cachedTop = element.scrollTop;
    var cachedLeft = element.scrollLeft;
    var topChanged = cachedTop !== meta.top;
    var leftChanged = cachedLeft !== meta.left;

    meta.top = cachedTop;
    meta.left = cachedLeft;

    var event = { top: cachedTop, left: cachedLeft };

    // TODO add explicit test
    if (topChanged || leftChanged) {
      run.begin();
      for (var j = 0; j < meta.handlers.length; j++) {
        meta.handlers[j](event);
      }
      run.end();
    }
  };

  ScrollHandler.prototype.poll = function poll() {
    var _this = this;

    this.isPolling = true;

    emberRafScheduler.scheduler.schedule('sync', function () {
      // TODO add explicit test
      if (!_this.isPolling) {
        return;
      }

      for (var i = 0; i < _this.length; i++) {
        var element = _this.elements[i];
        var info = _this.handlers[i];

        ScrollHandler.triggerElementHandlers(element, info);
      }

      _this.isPolling = _this.length > 0;
      // TODO add explicit test
      if (_this.isPolling) {
        _this.poll();
      }
    });
  };

  return ScrollHandler;
}();

var instance = new ScrollHandler();

function addScrollHandler(element, handler) {
  instance.addScrollHandler(element, handler);
}

function removeScrollHandler(element, handler) {
  instance.removeScrollHandler(element, handler);
}

/*
 * There are significant differences between browsers
 * in how they implement "scroll" on document.body
 *
 * The only cross-browser listener for scroll on body
 * is to listen on window with capture.
 *
 * They also implement different standards for how to
 * access the scroll position.
 *
 * This singleton class provides a cross-browser way
 * to access and set the scrollTop and scrollLeft properties.
 *
 */
function Container() {

  // A bug occurs in Chrome when we reload the browser at a lower
  // scrollTop, window.scrollY becomes stuck on a single value.
  Object.defineProperty(this, 'scrollTop', {
    get: function get() {
      return document.body.scrollTop || document.documentElement.scrollTop;
    },
    set: function set(v) {
      return document.body.scrollTop = document.documentElement.scrollTop = v;
    }
  });

  Object.defineProperty(this, 'scrollLeft', {
    get: function get() {
      return window.scrollX || window.pageXOffset || document.body.scrollLeft || document.documentElement.scrollLeft;
    },
    set: function set(v) {
      return window.scrollX = window.pageXOffset = document.body.scrollLeft = document.documentElement.scrollLeft = v;
    }
  });

  Object.defineProperty(this, 'offsetHeight', {
    get: function get() {
      return window.innerHeight;
    }
  });
}

Container.prototype.addEventListener = function addEventListener(event, handler, options) {
  return window.addEventListener(event, handler, options);
};

Container.prototype.removeEventListener = function addEventListener(event, handler, options) {
  return window.removeEventListener(event, handler, options);
};

Container.prototype.getBoundingClientRect = function getBoundingClientRect() {
  return {
    height: window.innerHeight,
    width: window.innerWidth,
    top: 0,
    left: 0,
    right: window.innerWidth,
    bottom: window.innerHeight
  };
};

var Container$1 = new Container();

function estimateElementHeight(element, fallbackHeight) {
  (true && !(fallbackHeight) && Ember.assert('You called estimateElement height without a fallbackHeight', fallbackHeight));
  (true && !(element) && Ember.assert('You called estimateElementHeight without an element', element));


  if (fallbackHeight.indexOf('%') !== -1) {
    return getPercentageHeight(element, fallbackHeight);
  }

  if (fallbackHeight.indexOf('em') !== -1) {
    return getEmHeight(element, fallbackHeight);
  }

  return parseInt(fallbackHeight, 10);
}

function getPercentageHeight(element, fallbackHeight) {
  // We use offsetHeight here to get the element's true height, rather than the
  // bounding rect which may be scaled with transforms
  var parentHeight = element.offsetHeight;
  var percent = parseFloat(fallbackHeight);

  return percent * parentHeight / 100.0;
}

function getEmHeight(element, fallbackHeight) {
  var fontSizeElement = fallbackHeight.indexOf('rem') !== -1 ? document.documentElement : element;
  var fontSize = window.getComputedStyle(fontSizeElement).getPropertyValue('font-size');

  return parseFloat(fallbackHeight) * parseFloat(fontSize);
}

function getScaledClientRect(element, scale) {
  var rect = element.getBoundingClientRect();

  if (scale === 1) {
    return rect;
  }

  var scaled = {};

  for (var key in rect) {
    scaled[key] = rect[key] * scale;
  }

  return scaled;
}

var _createClass$1 = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var A = Ember.A;
var _get = Ember.get;
var set = Ember.set;

var Radar = function () {
  function Radar(parentToken, _ref) {
    var _this = this;

    var bufferSize = _ref.bufferSize,
        containerSelector = _ref.containerSelector,
        estimateHeight = _ref.estimateHeight,
        items = _ref.items,
        renderAll = _ref.renderAll,
        renderFromLast = _ref.renderFromLast,
        initialRenderCount = _ref.initialRenderCount,
        shouldRecycle = _ref.shouldRecycle,
        startingIndex = _ref.startingIndex;

    this.token = new emberRafScheduler.Token(parentToken);

    // Public API
    this.bufferSize = bufferSize;
    this.containerSelector = containerSelector;
    this.estimateHeight = estimateHeight;
    this.initialRenderCount = initialRenderCount;
    this.items = items;
    this.renderAll = renderAll;
    this.renderFromLast = renderFromLast;
    this.shouldRecycle = shouldRecycle;
    this.startingIndex = startingIndex;

    // defaults to a no-op intentionally, actions will only be sent if they
    // are passed into the component
    this.sendAction = function () {};

    // Calculated constants
    this._itemContainer = null;
    this._scrollContainer = null;
    this._prependOffset = 0;
    this._calculatedEstimateHeight = 0;
    this._collectionOffset = 0;
    this._calculatedScrollContainerHeight = 0;
    this._transformScale = 1;

    // Event handler
    this._scrollHandler = function (_ref2) {
      var top = _ref2.top;

      // debounce scheduling updates by checking to make sure we've moved a minimum amount
      if (_this._didEarthquake(Math.abs(_this._scrollTop - top))) {
        _this.scheduleUpdate();
      }
    };

    this._resizeHandler = this.scheduleUpdate.bind(this);

    // Run state
    this._nextUpdate = null;
    this._nextLayout = null;
    this._started = false;
    this._didReset = true;

    // Cache state
    this._scrollTop = 0;
    // Setting these values to infinity starts us in a guaranteed good state for the radar,
    // so it knows that it needs to run certain measurements, etc.
    this._prevFirstItemIndex = Infinity;
    this._prevLastItemIndex = -Infinity;
    this._prevFirstVisibleIndex = 0;
    this._prevLastVisibleIndex = 0;
    this._firstReached = false;
    this._lastReached = false;
    this._componentPool = [];
    this._prependComponentPool = [];

    // Boundaries
    this._occludedContentBefore = new VirtualComponent();
    this._occludedContentAfter = new VirtualComponent();

    this._occludedContentBefore.element = document.createElement('occluded-content');
    this._occludedContentAfter.element = document.createElement('occluded-content');

    this._occludedContentBefore.element.addEventListener('click', this.pageUp.bind(this));
    this._occludedContentAfter.element.addEventListener('click', this.pageDown.bind(this));

    // Element to hold pooled component DOM when not in use
    this._domPool = document.createDocumentFragment();

    // Initialize virtual components
    this.virtualComponents = A([this._occludedContentBefore, this._occludedContentAfter]);
    this.orderedComponents = [];

    this._updateVirtualComponents();

    // In older versions of Ember/IE, binding anything on an object in the template
    // adds observers which creates __ember_meta__
    this.__ember_meta__ = null; // eslint-disable-line camelcase

    {
      this._debugDidUpdate = null;
    }
  }

  Radar.prototype.destroy = function destroy() {
    this.token.cancel();

    for (var i = 0; i < this.orderedComponents.length; i++) {
      this.orderedComponents[i].destroy();
    }

    this.orderedComponents = null;
    set(this, 'virtualComponents', null);

    if (this._started) {
      removeScrollHandler(this._scrollContainer, this._scrollHandler);
      Container$1.removeEventListener('resize', this._resizeHandler);
    }
  };

  Radar.prototype.schedule = function schedule(queueName, job) {
    return emberRafScheduler.scheduler.schedule(queueName, job, this.token);
  };

  /**
   * Start the Radar. Does initial measurements, adds event handlers,
   * sets up initial scroll state, and
   */


  Radar.prototype.start = function start() {
    var startingIndex = this.startingIndex,
        containerSelector = this.containerSelector,
        _occludedContentBefore = this._occludedContentBefore;

    // Use the occluded content element, which has been inserted into the DOM,
    // to find the item container and the scroll container

    this._itemContainer = _occludedContentBefore.element.parentNode;
    this._scrollContainer = containerSelector === 'body' ? Container$1 : closest(this._itemContainer, containerSelector);

    this._updateConstants();

    // Setup initial scroll state
    if (startingIndex !== 0) {
      var renderFromLast = this.renderFromLast,
          _calculatedEstimateHeight = this._calculatedEstimateHeight,
          _collectionOffset = this._collectionOffset,
          _calculatedScrollContainerHeight = this._calculatedScrollContainerHeight;


      var startingScrollTop = startingIndex * _calculatedEstimateHeight;

      if (renderFromLast) {
        startingScrollTop -= _calculatedScrollContainerHeight - _calculatedEstimateHeight;
      }

      // initialize the scrollTop value, which will be applied to the
      // scrollContainer after the collection has been initialized
      this._scrollTop = startingScrollTop + _collectionOffset;
    }

    this._started = true;
    this.update();

    // Setup event handlers
    addScrollHandler(this._scrollContainer, this._scrollHandler);
    Container$1.addEventListener('resize', this._resizeHandler);
  };

  /*
   * Schedules an update for the next RAF
   *
   * This will first run _updateVirtualComponents in the sync phase, which figures out what
   * components need to be rerendered and updates the appropriate VCs and moves their associated
   * DOM. At the end of the `sync` phase the runloop is flushed and Glimmer renders the changes.
   *
   * By the `affect` phase the Radar should have had time to measure, meaning it has all of the
   * current info and we can send actions for any changes.
   *
   * @private
   */


  Radar.prototype.scheduleUpdate = function scheduleUpdate() {
    var _this2 = this;

    if (this._nextUpdate !== null || this._started === false) {
      return;
    }

    this._nextUpdate = this.schedule('sync', function () {
      _this2._nextUpdate = null;
      _this2._scrollTop = _this2._scrollContainer.scrollTop;

      _this2.update();
    });
  };

  Radar.prototype.update = function update() {
    this._updateConstants();
    this._updateIndexes();
    this._updateVirtualComponents();

    this.schedule('measure', this.afterUpdate.bind(this));
  };

  Radar.prototype.afterUpdate = function afterUpdate() {
    var totalItems = this.totalItems;


    var scrollDiff = this._calculateScrollDiff();

    if (scrollDiff !== 0) {
      this._scrollContainer.scrollTop += scrollDiff;
    }

    // Re-sync scrollTop, since Chrome may have intervened
    this._scrollTop = this._scrollContainer.scrollTop;

    // Unset prepend offset, we're done with any prepend changes at this point
    this._prependOffset = 0;

    // Send actions if there are any items
    if (totalItems !== 0) {
      this._sendActions();
    }

    // Cache previous values
    this._prevFirstItemIndex = this.firstItemIndex;
    this._prevLastItemIndex = this.lastItemIndex;
    this._prevFirstVisibleIndex = this.firstVisibleIndex;
    this._prevLastVisibleIndex = this.lastVisibleIndex;

    // Clear the reset flag
    this._didReset = false;

    if (true && this._debugDidUpdate !== null) {
      // Hook to update the visual debugger
      this._debugDidUpdate(this);
    }
  };

  /*
   * The scroll diff is the difference between where we want the container's scrollTop to be,
   * and where it actually is right now. By default it accounts for the `_prependOffset`, which
   * is set when items are added to the front of the collection, as well as any discrepancies
   * that may have arisen between the cached `_scrollTop` value and the actually container's
   * scrollTop. The container's scrollTop may be modified by the browser when we manipulate DOM
   * (Chrome specifically does this a lot), so `_scrollTop` should be considered the canonical
   * scroll top.
   *
   * Subclasses should override this method to provide any difference between expected item size
   * pre-render and actual item size post-render.
   */


  Radar.prototype._calculateScrollDiff = function _calculateScrollDiff() {
    return this._prependOffset + this._scrollTop - this._scrollContainer.scrollTop;
  };

  Radar.prototype._updateConstants = function _updateConstants() {
    var estimateHeight = this.estimateHeight,
        _occludedContentBefore = this._occludedContentBefore,
        _itemContainer = this._itemContainer,
        _scrollContainer = this._scrollContainer;
    (true && !(estimateHeight !== null) && Ember.assert('Must provide a `estimateHeight` value to vertical-collection', estimateHeight !== null));
    (true && !(_itemContainer !== null) && Ember.assert('itemContainer must be set on Radar before scheduling an update', _itemContainer !== null));
    (true && !(_scrollContainer !== null) && Ember.assert('scrollContainer must be set on Radar before scheduling an update', _scrollContainer !== null));

    // The scroll container's offsetHeight will reflect the actual height of the element, while
    // it's measured height via bounding client rect will reflect the height with any transformations
    // applied. We use this to find out the scale of the items so we can store measurements at the
    // correct heights.

    var scrollContainerOffsetHeight = _scrollContainer.offsetHeight;

    var _scrollContainer$getB = _scrollContainer.getBoundingClientRect(),
        scrollContainerRenderedHeight = _scrollContainer$getB.height;

    var transformScale = void 0;

    // transformScale represents the opposite of the scale, if any, applied to the collection. Check for equality
    // to guard against floating point errors, and check to make sure we're not dividing by zero (default to scale 1 if so)
    if (scrollContainerOffsetHeight === scrollContainerRenderedHeight || scrollContainerRenderedHeight === 0) {
      transformScale = 1;
    } else {
      transformScale = scrollContainerOffsetHeight / scrollContainerRenderedHeight;
    }

    var _getScaledClientRect = getScaledClientRect(_occludedContentBefore, transformScale),
        scrollContentTop = _getScaledClientRect.top;

    var _getScaledClientRect2 = getScaledClientRect(_scrollContainer, transformScale),
        scrollContainerTop = _getScaledClientRect2.top;

    var scrollContainerMaxHeight = 0;

    if (_scrollContainer instanceof Element) {
      var maxHeightStyle = window.getComputedStyle(_scrollContainer).maxHeight;

      if (maxHeightStyle !== 'none') {
        scrollContainerMaxHeight = estimateElementHeight(_scrollContainer.parentElement, maxHeightStyle);
      }
    }

    var calculatedEstimateHeight = typeof estimateHeight === 'string' ? estimateElementHeight(_itemContainer, estimateHeight) : estimateHeight;

    (true && !(calculatedEstimateHeight > 0) && Ember.assert('calculatedEstimateHeight must be greater than 0, instead was "' + calculatedEstimateHeight + '" based on estimateHeight: ' + estimateHeight, calculatedEstimateHeight > 0));


    this._transformScale = transformScale;
    this._calculatedEstimateHeight = calculatedEstimateHeight;
    this._calculatedScrollContainerHeight = roundTo(Math.max(scrollContainerOffsetHeight, scrollContainerMaxHeight));

    // The offset between the top of the collection and the top of the scroll container. Determined by finding
    // the distance from the collection is from the top of the scroll container's content (scrollTop + actual position)
    // and subtracting the scroll containers actual top.
    this._collectionOffset = roundTo(_scrollContainer.scrollTop + scrollContentTop - scrollContainerTop);
  };

  /*
   * Updates virtualComponents, which is meant to be a static pool of components that we render to.
   * In order to decrease the time spent rendering and diffing, we pull the {{each}} out of the DOM
   * and only replace the content of _virtualComponents which are removed/added.
   *
   * For instance, if we start with the following and scroll down, items 2 and 3 do not need to be
   * rerendered, only item 1 needs to be removed and only item 4 needs to be added. So we replace
   * item 1 with item 4, and then manually move the DOM:
   *
   *   1                        4                         2
   *   2 -> replace 1 with 4 -> 2 -> manually move DOM -> 3
   *   3                        3                         4
   *
   * However, _virtualComponents is still out of order. Rather than keep track of the state of
   * things in _virtualComponents, we track the visually ordered components in the
   * _orderedComponents array. This is possible because all of our operations are relatively simple,
   * popping some number of components off one end and pushing them onto the other.
   *
   * @private
   */


  Radar.prototype._updateVirtualComponents = function _updateVirtualComponents() {
    var items = this.items,
        orderedComponents = this.orderedComponents,
        virtualComponents = this.virtualComponents,
        _componentPool = this._componentPool,
        shouldRecycle = this.shouldRecycle,
        renderAll = this.renderAll,
        _didReset = this._didReset,
        _started = this._started,
        _occludedContentBefore = this._occludedContentBefore,
        _occludedContentAfter = this._occludedContentAfter,
        totalItems = this.totalItems;


    var renderedFirstItemIndex = void 0,
        renderedLastItemIndex = void 0,
        renderedTotalBefore = void 0,
        renderedTotalAfter = void 0;

    if (renderAll === true) {
      // All items should be rendered, set indexes based on total item count
      renderedFirstItemIndex = 0;
      renderedLastItemIndex = totalItems - 1;
      renderedTotalBefore = 0;
      renderedTotalAfter = 0;
    } else if (_started === false) {
      // The Radar hasn't been started yet, render the initialRenderCount if it exists
      renderedFirstItemIndex = this.startingIndex;
      renderedLastItemIndex = this.startingIndex + this.initialRenderCount - 1;
      renderedTotalBefore = 0;
      renderedTotalAfter = 0;
    } else {
      renderedFirstItemIndex = this.firstItemIndex;
      renderedLastItemIndex = this.lastItemIndex;
      renderedTotalBefore = this.totalBefore;
      renderedTotalAfter = this.totalAfter;
    }

    // If there are less items available than rendered, we drop the last rendered item index
    renderedLastItemIndex = Math.min(renderedLastItemIndex, totalItems - 1);

    // Add components to be recycled to the pool
    while (orderedComponents.length > 0 && orderedComponents[0].index < renderedFirstItemIndex) {
      _componentPool.push(orderedComponents.shift());
    }

    while (orderedComponents.length > 0 && orderedComponents[orderedComponents.length - 1].index > renderedLastItemIndex) {
      _componentPool.unshift(orderedComponents.pop());
    }

    if (_didReset) {
      if (shouldRecycle === true) {
        for (var i = 0; i < orderedComponents.length; i++) {
          // If the underlying array has changed, the indexes could be the same but
          // the content may have changed, so recycle the remaining components
          var component = orderedComponents[i];
          component.recycle(objectAt(items, component.index), component.index);
        }
      } else {
        while (orderedComponents.length > 0) {
          // If recycling is disabled we need to delete all components and clear the array
          _componentPool.push(orderedComponents.shift());
        }
      }
    }

    var firstIndexInList = orderedComponents.length > 0 ? orderedComponents[0].index : renderedFirstItemIndex;
    var lastIndexInList = orderedComponents.length > 0 ? orderedComponents[orderedComponents.length - 1].index : renderedFirstItemIndex - 1;

    // Append as many items as needed to the rendered components
    while (lastIndexInList < renderedLastItemIndex) {
      var _component = void 0;

      if (shouldRecycle === true) {
        _component = _componentPool.pop() || new VirtualComponent();
      } else {
        _component = new VirtualComponent();
      }

      var itemIndex = ++lastIndexInList;

      _component.recycle(objectAt(items, itemIndex), itemIndex);
      this._appendComponent(_component);

      orderedComponents.push(_component);
    }

    // Prepend as many items as needed to the rendered components
    while (firstIndexInList > renderedFirstItemIndex) {
      var _component2 = void 0;

      if (shouldRecycle === true) {
        _component2 = _componentPool.pop() || new VirtualComponent();
      } else {
        _component2 = new VirtualComponent();
      }

      var _itemIndex = --firstIndexInList;

      _component2.recycle(objectAt(items, _itemIndex), _itemIndex);
      this._prependComponent(_component2);

      orderedComponents.unshift(_component2);
    }

    // If there are any items remaining in the pool, remove them
    if (_componentPool.length > 0) {
      if (shouldRecycle === true) {
        // Grab the DOM of the remaining components and move it to temporary node disconnected from
        // the body. If we end up using these components again, we'll grab their DOM and put it back
        for (var _i = 0; _i < _componentPool.length; _i++) {
          var _component3 = _componentPool[_i];

          insertRangeBefore(this._domPool, null, _component3.realUpperBound, _component3.realLowerBound);
        }
      } else {
        virtualComponents.removeObjects(_componentPool);
        _componentPool.length = 0;
      }
    }

    var totalItemsBefore = renderedFirstItemIndex;
    var totalItemsAfter = totalItems - renderedLastItemIndex - 1;

    var beforeItemsText = totalItemsBefore === 1 ? 'item' : 'items';
    var afterItemsText = totalItemsAfter === 1 ? 'item' : 'items';

    // Set padding element heights, unset itemContainer's minHeight
    _occludedContentBefore.element.style.height = Math.max(renderedTotalBefore, 0) + 'px';
    _occludedContentBefore.element.innerHTML = totalItemsBefore > 0 ? 'And ' + totalItemsBefore + ' ' + beforeItemsText + ' before' : '';

    _occludedContentAfter.element.style.height = Math.max(renderedTotalAfter, 0) + 'px';
    _occludedContentAfter.element.innerHTML = totalItemsAfter > 0 ? 'And ' + totalItemsAfter + ' ' + afterItemsText + ' after' : '';
  };

  Radar.prototype._appendComponent = function _appendComponent(component) {
    var virtualComponents = this.virtualComponents,
        _occludedContentAfter = this._occludedContentAfter,
        _itemContainer = this._itemContainer;


    var relativeNode = _occludedContentAfter.realUpperBound;

    if (component.rendered === true) {
      insertRangeBefore(_itemContainer, relativeNode, component.realUpperBound, component.realLowerBound);
    } else {
      virtualComponents.insertAt(virtualComponents.get('length') - 1, component);
      component.rendered = true;
    }
  };

  Radar.prototype._prependComponent = function _prependComponent(component) {
    var _this3 = this;

    var virtualComponents = this.virtualComponents,
        _occludedContentBefore = this._occludedContentBefore,
        _prependComponentPool = this._prependComponentPool,
        _itemContainer = this._itemContainer;


    var relativeNode = _occludedContentBefore.realLowerBound.nextSibling;

    if (component.rendered === true) {
      insertRangeBefore(_itemContainer, relativeNode, component.realUpperBound, component.realLowerBound);
    } else {
      virtualComponents.insertAt(virtualComponents.get('length') - 1, component);
      component.rendered = true;

      // Components that are both new and prepended still need to be rendered at the end because Glimmer.
      // We have to move them _after_ they render, so we schedule that if they exist
      _prependComponentPool.unshift(component);

      if (this._nextLayout === null) {
        this._nextLayout = this.schedule('layout', function () {
          _this3._nextLayout = null;

          while (_prependComponentPool.length > 0) {
            var _component4 = _prependComponentPool.pop();

            // Changes with each inserted component
            var _relativeNode = _occludedContentBefore.realLowerBound.nextSibling;

            insertRangeBefore(_itemContainer, _relativeNode, _component4.realUpperBound, _component4.realLowerBound);
          }
        });
      }
    }
  };

  Radar.prototype._sendActions = function _sendActions() {
    var firstItemIndex = this.firstItemIndex,
        lastItemIndex = this.lastItemIndex,
        firstVisibleIndex = this.firstVisibleIndex,
        lastVisibleIndex = this.lastVisibleIndex,
        _prevFirstVisibleIndex = this._prevFirstVisibleIndex,
        _prevLastVisibleIndex = this._prevLastVisibleIndex,
        totalItems = this.totalItems,
        _firstReached = this._firstReached,
        _lastReached = this._lastReached,
        _didReset = this._didReset;


    if (_didReset || firstVisibleIndex !== _prevFirstVisibleIndex) {
      this.sendAction('firstVisibleChanged', firstVisibleIndex);
    }

    if (_didReset || lastVisibleIndex !== _prevLastVisibleIndex) {
      this.sendAction('lastVisibleChanged', lastVisibleIndex);
    }

    if (_firstReached === false && firstItemIndex === 0) {
      this.sendAction('firstReached', firstItemIndex);
      this._firstReached = true;
    }

    if (_lastReached === false && lastItemIndex === totalItems - 1) {
      this.sendAction('lastReached', lastItemIndex);
      this._lastReached = true;
    }
  };

  Radar.prototype.prepend = function prepend(numPrepended) {
    this._prevFirstItemIndex += numPrepended;
    this._prevLastItemIndex += numPrepended;

    this.orderedComponents.forEach(function (c) {
      return set(c, 'index', _get(c, 'index') + numPrepended);
    });

    this._firstReached = false;

    this._prependOffset = numPrepended * this._calculatedEstimateHeight;
  };

  Radar.prototype.append = function append() {
    this._lastReached = false;
  };

  Radar.prototype.reset = function reset() {
    this._firstReached = false;
    this._lastReached = false;
    this._didReset = true;
  };

  Radar.prototype.pageUp = function pageUp() {
    if (this.renderAll) {
      return; // All items rendered, no need to page up
    }

    var bufferSize = this.bufferSize,
        firstItemIndex = this.firstItemIndex,
        totalComponents = this.totalComponents;


    if (firstItemIndex !== 0) {
      var newFirstItemIndex = Math.max(firstItemIndex - totalComponents + bufferSize, 0);
      var offset = this.getOffsetForIndex(newFirstItemIndex);

      this._scrollContainer.scrollTop = offset + this._collectionOffset;
      this.scheduleUpdate();
    }
  };

  Radar.prototype.pageDown = function pageDown() {
    if (this.renderAll) {
      return; // All items rendered, no need to page down
    }

    var bufferSize = this.bufferSize,
        lastItemIndex = this.lastItemIndex,
        totalComponents = this.totalComponents,
        totalItems = this.totalItems;


    if (lastItemIndex !== totalItems - 1) {
      var newFirstItemIndex = Math.min(lastItemIndex + bufferSize + 1, totalItems - totalComponents);
      var offset = this.getOffsetForIndex(newFirstItemIndex);

      this._scrollContainer.scrollTop = offset + this._collectionOffset;
      this.scheduleUpdate();
    }
  };

  _createClass$1(Radar, [{
    key: 'totalComponents',
    get: function get() {
      return Math.min(this.totalItems, this.lastItemIndex - this.firstItemIndex + 1);
    }

    /*
     * `prependOffset` exists because there are times when we need to do the following in this exact
     * order:
     *
     * 1. Prepend, which means we need to adjust the scroll position by `estimateHeight * numPrepended`
     * 2. Calculate the items that will be displayed after the prepend, and move VCs around as
     *    necessary (`scheduleUpdate`).
     * 3. Actually add the amount prepended to `scrollContainer.scrollTop`
     *
     * This is due to some strange behavior in Chrome where it will modify `scrollTop` on it's own
     * when prepending item elements. We seem to avoid this behavior by doing these things in a RAF
     * in this exact order.
     */

  }, {
    key: 'visibleTop',
    get: function get() {
      return Math.max(this._scrollTop - this._collectionOffset + this._prependOffset, 0);
    }
  }, {
    key: 'visibleMiddle',
    get: function get() {
      return this.visibleTop + this._calculatedScrollContainerHeight / 2;
    }
  }, {
    key: 'visibleBottom',
    get: function get() {
      // There is a case where the container of this vertical collection could have height 0 at
      // initial render step but will be updated later. We want to return visibleBottom to be 0 rather
      // than -1.
      return Math.max(this.visibleTop + this._calculatedScrollContainerHeight - 1, 0);
    }
  }, {
    key: 'totalItems',
    get: function get() {
      return this.items ? _get(this.items, 'length') : 0;
    }
  }]);

  return Radar;
}();

/*
 * `SkipList` is a data structure designed with two main uses in mind:
 *
 * - Given a target value, find the index i in the list such that
 * `sum(list[0]..list[i]) <= value < sum(list[0]..list[i + 1])`
 *
 * - Given the index i (the fulcrum point) from above, get `sum(list[0]..list[i])`
 *   and `sum(list[i + 1]..list[-1])`
 *
 * The idea is that given a list of arbitrary heights or widths in pixels, we want to find
 * the index of the item such that when all of the items before it are added together, it will
 * be as close to the target (scrollTop of our container) as possible.
 *
 * This data structure acts somewhat like a Binary Search Tree. Given a list of size n, the
 * retreival time for the index is O(log n) and the update time should any values change is
 * O(log n). The space complexity is O(n log n) in bytes (using Float32Arrays helps a lot
 * here), and the initialization time is O(n log n).
 *
 * It works by constructing layer arrays, each of which is setup such that
 * `layer[i] = prevLayer[i * 2] + prevLayer[(i * 2) + 1]`. This allows us to traverse the layers
 * downward using a binary search to arrive at the index we want. We also add the values up as we
 * traverse to get the total value before and after the final index.
 */

function fill(array, value) {
  var start = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  var end = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : array.length;

  if (typeof array.fill === 'function') {
    array.fill(value, start, end);
  } else {
    for (; start < end; start++) {
      array[start] = value;
    }

    return array;
  }
}

function subarray(array, start, end) {
  if (typeof array.subarray === 'function') {
    return array.subarray(start, end);
  } else {
    return array.slice(start, end);
  }
}

var SkipList = function () {
  function SkipList(length, defaultValue) {
    var values = new Float32Array(new ArrayBuffer(length * 4));
    fill(values, defaultValue);

    this.length = length;
    this.defaultValue = defaultValue;

    this._initializeLayers(values, defaultValue);

    {
      Object.preventExtensions(this);
    }
  }

  SkipList.prototype._initializeLayers = function _initializeLayers(values, defaultValue) {
    var layers = [values];
    var i = void 0,
        length = void 0,
        layer = void 0,
        prevLayer = void 0,
        left = void 0,
        right = void 0;

    prevLayer = layer = values;
    length = values.length;

    while (length > 2) {
      length = Math.ceil(length / 2);

      layer = new Float32Array(new ArrayBuffer(length * 4));

      if (defaultValue !== undefined) {
        // If given a default value we assume that we can fill each
        // layer of the skip list with the previous layer's value * 2.
        // This allows us to use the `fill` method on Typed arrays, which
        // an order of magnitude faster than manually calculating each value.
        defaultValue = defaultValue * 2;
        fill(layer, defaultValue);

        left = prevLayer[(length - 1) * 2] || 0;
        right = prevLayer[(length - 1) * 2 + 1] || 0;

        // Layers are not powers of 2, and sometimes they may by odd sizes.
        // Only the last value of a layer will be different, so we calculate
        // its value manually.
        layer[length - 1] = left + right;
      } else {
        for (i = 0; i < length; i++) {
          left = prevLayer[i * 2];
          right = prevLayer[i * 2 + 1];
          layer[i] = right ? left + right : left;
        }
      }

      layers.unshift(layer);
      prevLayer = layer;
    }

    this.total = layer.length > 0 ? layer.length > 1 ? layer[0] + layer[1] : layer[0] : 0;

    (true && !(typeof this.total === 'number') && Ember.assert('total must be a number', typeof this.total === 'number'));


    this.layers = layers;
    this.values = values;
  };

  SkipList.prototype.find = function find(targetValue) {
    var layers = this.layers,
        total = this.total,
        length = this.length,
        values = this.values;

    var numLayers = layers.length;

    if (length === 0) {
      return { index: 0, totalBefore: 0, totalAfter: 0 };
    }

    var i = void 0,
        layer = void 0,
        left = void 0,
        leftIndex = void 0,
        rightIndex = void 0;
    var index = 0;
    var totalBefore = 0;
    var totalAfter = 0;

    targetValue = Math.min(total - 1, targetValue);

    (true && !(typeof targetValue === 'number') && Ember.assert('targetValue must be a number', typeof targetValue === 'number'));
    (true && !(targetValue >= 0) && Ember.assert('targetValue must be greater than or equal to 0', targetValue >= 0));
    (true && !(targetValue < total) && Ember.assert('targetValue must be no more than total', targetValue < total));


    for (i = 0; i < numLayers; i++) {
      layer = layers[i];

      leftIndex = index;
      rightIndex = index + 1;

      left = layer[leftIndex];

      if (targetValue >= totalBefore + left) {
        totalBefore = totalBefore + left;
        index = rightIndex * 2;
      } else {
        index = leftIndex * 2;
      }
    }

    index = index / 2;

    (true && !(typeof index === 'number') && Ember.assert('index must be a number', typeof index === 'number'));
    (true && !(index >= 0 && index < this.values.length) && Ember.assert('index must be within bounds', index >= 0 && index < this.values.length));


    totalAfter = total - (totalBefore + values[index]);

    return { index: index, totalBefore: totalBefore, totalAfter: totalAfter };
  };

  SkipList.prototype.getOffset = function getOffset(targetIndex) {
    var layers = this.layers,
        length = this.length,
        values = this.values;

    var numLayers = layers.length;

    if (length === 0) {
      return 0;
    }

    var index = 0;
    var offset = 0;

    for (var i = 0; i < numLayers - 1; i++) {
      var layer = layers[i];

      var leftIndex = index;
      var rightIndex = index + 1;

      if (targetIndex >= rightIndex * Math.pow(2, numLayers - i - 1)) {
        offset = offset + layer[leftIndex];
        index = rightIndex * 2;
      } else {
        index = leftIndex * 2;
      }
    }

    if (index + 1 === targetIndex) {
      offset += values[index];
    }

    return offset;
  };

  SkipList.prototype.set = function set(index, value) {
    (true && !(typeof value === 'number') && Ember.assert('value must be a number', typeof value === 'number'));
    (true && !(value >= 0) && Ember.assert('value must non-negative', value >= 0));
    (true && !(typeof index === 'number') && Ember.assert('index must be a number', typeof index === 'number'));
    (true && !(index >= 0 && index < this.values.length) && Ember.assert('index must be within bounds', index >= 0 && index < this.values.length));
    var layers = this.layers;

    var oldValue = layers[layers.length - 1][index];
    var delta = roundTo(value - oldValue);

    if (delta === 0) {
      return delta;
    }

    var i = void 0,
        layer = void 0;

    for (i = layers.length - 1; i >= 0; i--) {
      layer = layers[i];

      layer[index] += delta;

      index = Math.floor(index / 2);
    }

    this.total += delta;

    return delta;
  };

  SkipList.prototype.prepend = function prepend(numPrepended) {
    var oldValues = this.values,
        oldLength = this.length,
        defaultValue = this.defaultValue;


    var newLength = numPrepended + oldLength;

    var newValues = new Float32Array(new ArrayBuffer(newLength * 4));

    newValues.set(oldValues, numPrepended);
    fill(newValues, defaultValue, 0, numPrepended);

    this.length = newLength;
    this._initializeLayers(newValues);
  };

  SkipList.prototype.append = function append(numAppended) {
    var oldValues = this.values,
        oldLength = this.length,
        defaultValue = this.defaultValue;


    var newLength = numAppended + oldLength;

    var newValues = new Float32Array(new ArrayBuffer(newLength * 4));

    newValues.set(oldValues);
    fill(newValues, defaultValue, oldLength);

    this.length = newLength;
    this._initializeLayers(newValues);
  };

  SkipList.prototype.reset = function reset(newLength) {
    var oldValues = this.values,
        oldLength = this.length,
        defaultValue = this.defaultValue;


    if (oldLength === newLength) {
      return;
    }

    var newValues = new Float32Array(new ArrayBuffer(newLength * 4));

    if (oldLength < newLength) {
      newValues.set(oldValues);
      fill(newValues, defaultValue, oldLength);
    } else {
      newValues.set(subarray(oldValues, 0, newLength));
    }

    this.length = newLength;

    if (oldLength === 0) {
      this._initializeLayers(newValues, defaultValue);
    } else {
      this._initializeLayers(newValues);
    }
  };

  return SkipList;
}();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var DynamicRadar = function (_Radar) {
  _inherits(DynamicRadar, _Radar);

  function DynamicRadar(parentToken, options) {
    var _this = _possibleConstructorReturn(this, _Radar.call(this, parentToken, options));

    _this._firstItemIndex = 0;
    _this._lastItemIndex = 0;

    _this._totalBefore = 0;
    _this._totalAfter = 0;

    _this._minHeight = Infinity;

    _this._nextIncrementalRender = null;

    _this.skipList = null;

    {
      Object.preventExtensions(_this);
    }
    return _this;
  }

  DynamicRadar.prototype.destroy = function destroy() {
    _Radar.prototype.destroy.call(this);

    this.skipList = null;
  };

  DynamicRadar.prototype.scheduleUpdate = function scheduleUpdate() {
    // Cancel incremental render check, since we'll be remeasuring anyways
    if (this._nextIncrementalRender !== null) {
      this._nextIncrementalRender.cancel();
      this._nextIncrementalRender = null;
    }

    _Radar.prototype.scheduleUpdate.call(this);
  };

  DynamicRadar.prototype.afterUpdate = function afterUpdate() {
    var _this2 = this;

    // Schedule a check to see if we should rerender
    if (this._nextIncrementalRender === null && this._nextUpdate === null) {
      this._nextIncrementalRender = this.schedule('sync', function () {
        _this2._nextIncrementalRender = null;

        if (_this2._shouldScheduleRerender()) {
          _this2.update();
        }
      });
    }

    _Radar.prototype.afterUpdate.call(this);
  };

  DynamicRadar.prototype._updateConstants = function _updateConstants() {
    _Radar.prototype._updateConstants.call(this);

    if (this._calculatedEstimateHeight < this._minHeight) {
      this._minHeight = this._calculatedEstimateHeight;
    }

    // Create the SkipList only after the estimateHeight has been calculated the first time
    if (this.skipList === null) {
      this.skipList = new SkipList(this.totalItems, this._calculatedEstimateHeight);
    } else {
      this.skipList.defaultValue = this._calculatedEstimateHeight;
    }
  };

  DynamicRadar.prototype._updateIndexes = function _updateIndexes() {
    var bufferSize = this.bufferSize,
        skipList = this.skipList,
        visibleTop = this.visibleTop,
        visibleBottom = this.visibleBottom,
        totalItems = this.totalItems,
        _didReset = this._didReset;


    if (totalItems === 0) {
      this._firstItemIndex = 0;
      this._lastItemIndex = -1;
      this._totalBefore = 0;
      this._totalAfter = 0;

      return;
    }

    // Don't measure if the radar has just been instantiated or reset, as we are rendering with a
    // completely new set of items and won't get an accurate measurement until after they render the
    // first time.
    if (_didReset === false) {
      this._measure();
    }

    var values = skipList.values;

    var _skipList$find = this.skipList.find(visibleTop),
        totalBefore = _skipList$find.totalBefore,
        firstVisibleIndex = _skipList$find.index;

    var _skipList$find2 = this.skipList.find(visibleBottom),
        totalAfter = _skipList$find2.totalAfter,
        lastVisibleIndex = _skipList$find2.index;

    var maxIndex = totalItems - 1;

    var firstItemIndex = firstVisibleIndex;
    var lastItemIndex = lastVisibleIndex;

    // Add buffers
    for (var i = bufferSize; i > 0 && firstItemIndex > 0; i--) {
      firstItemIndex--;
      totalBefore -= values[firstItemIndex];
    }

    for (var _i = bufferSize; _i > 0 && lastItemIndex < maxIndex; _i--) {
      lastItemIndex++;
      totalAfter -= values[lastItemIndex];
    }

    this._firstItemIndex = firstItemIndex;
    this._lastItemIndex = lastItemIndex;
    this._totalBefore = totalBefore;
    this._totalAfter = totalAfter;
  };

  DynamicRadar.prototype._calculateScrollDiff = function _calculateScrollDiff() {
    var firstItemIndex = this.firstItemIndex,
        _prevFirstVisibleIndex = this._prevFirstVisibleIndex,
        _prevFirstItemIndex = this._prevFirstItemIndex;


    var beforeVisibleDiff = 0;

    if (firstItemIndex < _prevFirstItemIndex) {
      // Measurement only items that could affect scrollTop. This will necesarilly be the
      // minimum of the either the total number of items that are rendered up to the first
      // visible item, OR the number of items that changed before the first visible item
      // (the delta). We want to measure the delta of exactly this number of items, because
      // items that are after the first visible item should not affect the scroll position,
      // and neither should items already rendered before the first visible item.
      var measureLimit = Math.min(Math.abs(firstItemIndex - _prevFirstItemIndex), _prevFirstVisibleIndex - firstItemIndex);

      beforeVisibleDiff = Math.round(this._measure(measureLimit));
    }

    return beforeVisibleDiff + _Radar.prototype._calculateScrollDiff.call(this);
  };

  DynamicRadar.prototype._shouldScheduleRerender = function _shouldScheduleRerender() {
    var firstItemIndex = this.firstItemIndex,
        lastItemIndex = this.lastItemIndex;


    this._measure();

    // These indexes could change after the measurement, and in the incremental render
    // case we want to check them _after_ the change.
    var firstVisibleIndex = this.firstVisibleIndex,
        lastVisibleIndex = this.lastVisibleIndex;


    return firstVisibleIndex < firstItemIndex || lastVisibleIndex > lastItemIndex;
  };

  DynamicRadar.prototype._measure = function _measure() {
    var measureLimit = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var orderedComponents = this.orderedComponents,
        skipList = this.skipList,
        _occludedContentBefore = this._occludedContentBefore,
        _transformScale = this._transformScale;


    var numToMeasure = measureLimit !== null ? Math.min(measureLimit, orderedComponents.length) : orderedComponents.length;

    var totalDelta = 0;

    for (var i = 0; i < numToMeasure; i++) {
      var currentItem = orderedComponents[i];
      var previousItem = orderedComponents[i - 1];
      var itemIndex = currentItem.index;

      var _getScaledClientRect = getScaledClientRect(currentItem, _transformScale),
          currentItemTop = _getScaledClientRect.top,
          currentItemHeight = _getScaledClientRect.height;

      var margin = void 0;

      if (previousItem !== undefined) {
        margin = currentItemTop - getScaledClientRect(previousItem, _transformScale).bottom;
      } else {
        margin = currentItemTop - getScaledClientRect(_occludedContentBefore, _transformScale).bottom;
      }

      var newHeight = roundTo(currentItemHeight + margin);
      var itemDelta = skipList.set(itemIndex, newHeight);

      if (newHeight < this._minHeight) {
        this._minHeight = newHeight;
      }

      if (itemDelta !== 0) {
        totalDelta += itemDelta;
      }
    }

    return totalDelta;
  };

  DynamicRadar.prototype._didEarthquake = function _didEarthquake(scrollDiff) {
    return scrollDiff > this._minHeight / 2;
  };

  DynamicRadar.prototype.prepend = function prepend(numPrepended) {
    _Radar.prototype.prepend.call(this, numPrepended);

    this.skipList.prepend(numPrepended);
  };

  DynamicRadar.prototype.append = function append(numAppended) {
    _Radar.prototype.append.call(this, numAppended);

    this.skipList.append(numAppended);
  };

  DynamicRadar.prototype.reset = function reset() {
    _Radar.prototype.reset.call(this);

    if (this.skipList !== null) {
      this.skipList.reset(this.totalItems);
    }
  };

  /*
   * Public API to query the skiplist for the offset of an item
   */


  DynamicRadar.prototype.getOffsetForIndex = function getOffsetForIndex(index) {
    this._measure();

    return this.skipList.getOffset(index);
  };

  _createClass(DynamicRadar, [{
    key: 'total',
    get: function get() {
      return this.skipList.total;
    }
  }, {
    key: 'totalBefore',
    get: function get() {
      return this._totalBefore;
    }
  }, {
    key: 'totalAfter',
    get: function get() {
      return this._totalAfter;
    }
  }, {
    key: 'firstItemIndex',
    get: function get() {
      return this._firstItemIndex;
    }
  }, {
    key: 'lastItemIndex',
    get: function get() {
      return this._lastItemIndex;
    }
  }, {
    key: 'firstVisibleIndex',
    get: function get() {
      var visibleTop = this.visibleTop;

      var _skipList$find3 = this.skipList.find(visibleTop),
          index = _skipList$find3.index;

      return index;
    }
  }, {
    key: 'lastVisibleIndex',
    get: function get() {
      var visibleBottom = this.visibleBottom,
          totalItems = this.totalItems;

      var _skipList$find4 = this.skipList.find(visibleBottom),
          index = _skipList$find4.index;

      return Math.min(index, totalItems - 1);
    }
  }]);

  return DynamicRadar;
}(Radar);

var _createClass$3 = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _possibleConstructorReturn$1(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits$1(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var StaticRadar = function (_Radar) {
  _inherits$1(StaticRadar, _Radar);

  function StaticRadar(parentToken, options) {
    var _this = _possibleConstructorReturn$1(this, _Radar.call(this, parentToken, options));

    _this._firstItemIndex = 0;
    _this._lastItemIndex = 0;

    {
      Object.preventExtensions(_this);
    }
    return _this;
  }

  StaticRadar.prototype._updateIndexes = function _updateIndexes() {
    var bufferSize = this.bufferSize,
        totalItems = this.totalItems,
        visibleMiddle = this.visibleMiddle,
        _calculatedEstimateHeight = this._calculatedEstimateHeight,
        _calculatedScrollContainerHeight = this._calculatedScrollContainerHeight;


    if (totalItems === 0) {
      this._firstItemIndex = 0;
      this._lastItemIndex = -1;

      return;
    }

    var maxIndex = totalItems - 1;

    var middleItemIndex = Math.floor(visibleMiddle / _calculatedEstimateHeight);

    var shouldRenderCount = Math.min(Math.ceil(_calculatedScrollContainerHeight / _calculatedEstimateHeight), totalItems);

    var firstItemIndex = middleItemIndex - Math.floor(shouldRenderCount / 2);
    var lastItemIndex = middleItemIndex + Math.ceil(shouldRenderCount / 2) - 1;

    if (firstItemIndex < 0) {
      firstItemIndex = 0;
      lastItemIndex = shouldRenderCount - 1;
    }

    if (lastItemIndex > maxIndex) {
      lastItemIndex = maxIndex;
      firstItemIndex = maxIndex - (shouldRenderCount - 1);
    }

    firstItemIndex = Math.max(firstItemIndex - bufferSize, 0);
    lastItemIndex = Math.min(lastItemIndex + bufferSize, maxIndex);

    this._firstItemIndex = firstItemIndex;
    this._lastItemIndex = lastItemIndex;
  };

  StaticRadar.prototype._didEarthquake = function _didEarthquake(scrollDiff) {
    return scrollDiff > this._calculatedEstimateHeight / 2;
  };

  /*
   * Public API to query for the offset of an item
   */
  StaticRadar.prototype.getOffsetForIndex = function getOffsetForIndex(index) {
    return index * this._calculatedEstimateHeight + 1;
  };

  _createClass$3(StaticRadar, [{
    key: 'total',
    get: function get() {
      return this.totalItems * this._calculatedEstimateHeight;
    }
  }, {
    key: 'totalBefore',
    get: function get() {
      return this.firstItemIndex * this._calculatedEstimateHeight;
    }
  }, {
    key: 'totalAfter',
    get: function get() {
      return this.total - (this.lastItemIndex + 1) * this._calculatedEstimateHeight;
    }
  }, {
    key: 'firstItemIndex',
    get: function get() {
      return this._firstItemIndex;
    }
  }, {
    key: 'lastItemIndex',
    get: function get() {
      return this._lastItemIndex;
    }
  }, {
    key: 'firstVisibleIndex',
    get: function get() {
      return Math.ceil(this.visibleTop / this._calculatedEstimateHeight);
    }
  }, {
    key: 'lastVisibleIndex',
    get: function get() {
      return Math.min(Math.ceil(this.visibleBottom / this._calculatedEstimateHeight), this.totalItems) - 1;
    }
  }]);

  return StaticRadar;
}(Radar);

exports.keyForItem = keyForItem;
exports.closestElement = closest;
exports.DynamicRadar = DynamicRadar;
exports.StaticRadar = StaticRadar;
exports.Container = Container$1;
exports.objectAt = objectAt;
exports.addScrollHandler = addScrollHandler;
exports.removeScrollHandler = removeScrollHandler;
exports.ScrollHandler = ScrollHandler;

Object.defineProperty(exports, '__esModule', { value: true });

});
